/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import { connect } from 'react-redux'
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import MsgInput from '../../../Error/MsgInput'
import {
  Form,
  Header,
  Button,
  Loader,
  Dimmer,
  Icon,
  Modal,
  Grid,
  Input,
  Table,
} from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';

class BillModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      message_error_hidden: true,
      items: [],
      data: null,
      description: '',
      msg_error: {},
      focus: {},
    }
  }

  submitSaveInvoice(confirm) {
    let { auth } = this.props
    let msg_error = {}
    if (this.state.description.length > 255) {
      msg_error['description'] = ['ไม่สามารถกรอกหมายเหตุ ได้มากกว่า 255 ตัวอักษร']
    }

    this.setState({ msg_error: msg_error })
    if (Object.keys(msg_error).length > 0) {
      return;
    }
    if (auth.role != 'S' && auth.role != 'A') {
      alert('คุณไม่มีสิทธิ์บันทึกรายการ')
      return
    }

    var formData = {
      description: this.state.description,
      items: JSON.stringify(this.state.items.map((o) => { return { id: o.id, total: o.total } }))
    };
    this.setState({ button_save_loading: true })
    const url = `${Settings.baseUrl}/return-cash-pawned/${this.props.edit}/`;
    if (confirm) {
      Utility.post(url, formData, (status, data) => {
        if (status) {
          this.loadInvoice();
          alert('อนุมัติสำเร็จ')
          this.setState({ button_save_loading: false })
        } else {
          alert(data.detail || 'มีข้อผิดพลาดในระบบ')
          this.setState({ button_save_loading: false })
        }
      });
    } else {
      Utility.patch(url, formData, (status, data) => {
        if (status) {
          this.loadInvoice();
          alert('บันทึกสำเร็จ')
          this.setState({ button_save_loading: false })
        } else {
          alert(data.detail || 'มีข้อผิดพลาดในระบบ')
          this.setState({ button_save_loading: false })
        }
      });
    }
  }

  hideInvoice() {
    this.setState({ button_save_loading: true })
    const url = `${Settings.baseUrl}/return-cash-pawned/${this.props.edit}/`;
    Utility.delete(url, (status, data) => {
      console.log(status, data)
      if (status) {
        this.props.onClose()
        alert('ลบรายการสำเร็จ')
        this.loadInvoice();
      } else {
        alert(data.detail || 'มีข้อผิดพลาดในระบบ')
        this.setState({ button_save_loading: false })
      }
    });
  }

  loadInvoice() {
    const { auth } = this.props
    var invoices = Utility.getAsync(`${Settings.baseUrl}/return-cash-pawned/${this.props.edit}/`);
    this.setState({ loader: true });
    Promise
      .all([invoices])
      .then((values) => {
        const resp = values[0]
        if (resp.status_code === 200) {
          this.setState({
            description: resp.data.data.description,
            data: resp.data.data,
            items: resp.data.items,
            loader: false,
          })
        } else {
          alert('ไม่พบรายการ')
          this.props.onClose()
        }
      });
  }

  componentDidMount() {
    this.setState({ loader_active: true, btn_stock: true });
    this.loadInvoice();
  }

  render() {
    let items = this.state.items;
    return (
      <Modal size="large" open={true} /*dimmer='blurring'*/ >
        < Button id='btnClose' circular icon='close' basic floated='right' name='' onClick={this.props.onClose} />
        <Modal.Header>
          {this.state.data && <Header as='h4'>
            <Header.Content>
              <span>[บิลเลขที่ {this.state.data.number}]</span>
              <Header.Subheader>
                <span><Icon
                  name={this.state.data.status_stock == 'N'
                    ? 'warning circle'
                    : 'check circle'}
                  color={this.state.data.status_stock == 'N'
                    ? 'yellow'
                    : 'green'} />{this.state.data.status_stock_display}</span>
              </Header.Subheader>
            </Header.Content>
          </Header>}
        </Modal.Header> < Modal.Content > <div >
          <div className='relative'>
            {this.state.loader && <Dimmer active={this.state.loader} inverted>
              <Loader inverted content='Loading' />
            </Dimmer>}
            <div>
              <Grid >
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Form className='fluid' size='small'>
                      <Form.Group >
                        <Form.Field width={16}>
                          <label>วันที่รายการ</label>
                          <Input
                            readOnly={true}
                            className='text-right'
                            value={this.state.data ? Utility.formatDateTime(this.state.data.record_datetime) : ''}
                            disabled={true}
                          />
                        </Form.Field>
                      </Form.Group >
                      {(this.state.data && this.state.data.stock_datetime) &&
                        <Form.Group >
                          <Form.Field width={16}>
                            <label>วันที่อนุมัติ</label>
                            <Input
                              readOnly={true}
                              className='text-right'
                              value={this.state.data ? Utility.formatDateTime(this.state.data.stock_datetime) : ''}
                              disabled={true}
                            />
                          </Form.Field>
                        </Form.Group >
                      }
                      <Form.Group>
                        <Form.Field width={16}>
                          <label>ยอดเงินทั้งหมด</label>
                          <Input
                            readOnly={true}
                            className='text-right'
                            value={this.state.data ? Utility.numberFormat(Number(this.state.data.total)) : ''}
                            disabled={true}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group >
                        <Form.TextArea
                          error={this.state.msg_error.description}
                          label={this.state.msg_error.description ? <label>หมายเหตุ <MsgInput text={this.state.msg_error.description} /></label> : 'หมายเหตุ'}
                          placeholder='หมายเหตุ'
                          disabled={this.state.button_save_loading}
                          width={16}
                          maxLength={255}
                          value={this.state.description}
                          onChange={(e, v) => {
                            this.setState({ description: v.value })
                          }} />
                      </Form.Group>
                    </Form>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Table >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width='2'>สาขา</Table.HeaderCell>
                          <Table.HeaderCell textAlign='right' width='3'>เงินสำรองบริษัท</Table.HeaderCell>
                          <Table.HeaderCell textAlign='right' width='1'>สัดส่วน</Table.HeaderCell>
                          <Table.HeaderCell textAlign='right' width='3'>ยอดคาดการณ์</Table.HeaderCell>
                          <Table.HeaderCell textAlign='right' width='3'>ยอดเงิน</Table.HeaderCell>
                          {(this.state.data && this.state.data.status_stock === 'Y') ? <Table.HeaderCell width='4'>เลขอ้างอิง</Table.HeaderCell> : null}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {items.map((o, i) => {
                          return (
                            <Table.Row key={o.branch}>
                              <Table.Cell>{o.branch__name}</Table.Cell>
                              <Table.Cell textAlign='right'>{Utility.priceFormat(o.reserve_company)}</Table.Cell>
                              <Table.Cell textAlign='right'>{o.ratio}%</Table.Cell>
                              <Table.Cell textAlign='right'>{Utility.numberFormat(o.estimate)}</Table.Cell>
                              <Table.Cell textAlign='right'>
                                {(this.state.data && this.state.data.status_stock !== 'Y') ? <Input
                                  size='small'
                                  fluid
                                  disabled={this.state.button_save_loading || (this.state.data && this.state.data.status_stock === 'Y')}
                                  autoComplete="off"
                                  placeholder='0'
                                  className='text-right'
                                  onChange={(e) => {
                                    e.target.value = e.target.value.replaceAll(',', '').trim()
                                    if (isNaN(Number(e.target.value))) {
                                    } else {
                                      if (e.target.value.split('.')[0].length > 10) {
                                        return;
                                      }
                                      if (e.target.value.toString().split(".")[1] != undefined)
                                        if (e.target.value.toString().split(".")[1].length > 0)
                                          return
                                      items[i].total = e.target.value.replace(/^0+/, '')
                                      this.setState({ items: items })
                                    }
                                  }}
                                  onBlur={() => {
                                    setTimeout(() => {
                                      if (this.state.focus[`i_${i}`]) {
                                        this.setState({
                                          focus: {},
                                        });
                                      }
                                    }, 0);
                                  }}
                                  onFocus={(e) => {
                                    e.target.select()
                                    if (!this.state.focus[`i_${i}`]) {
                                      this.setState({
                                        focus: { [`i_${i}`]: true },
                                      });
                                    }
                                  }}
                                  value={this.state.focus[`i_${i}`] ? o.total : Utility.numberFormat(o.total)}
                                  maxLength={10}
                                /> : Utility.numberFormat(o.total)}
                              </Table.Cell>
                              {this.state.data.status_stock === 'Y' && <Table.Cell>{o.ref}</Table.Cell>}
                            </Table.Row>
                          )
                        })}
                      </Table.Body>
                    </Table>
                    <br />
                    <Form className='fluid' size='small'>
                      <Form.Group >
                        <Form.Input
                          label='จำนวนสาขา'
                          placeholder=''
                          className='text-right'
                          width={4}
                          value={items.length}
                          readOnly />
                        <Form.Input
                          label='เงินสำรองบริษัท'
                          placeholder=''
                          className='text-right'
                          width={4}
                          value={Utility.numberFormat(items.reduce((sum, item) => sum + (item.reserve_company), 0))}
                          readOnly />
                        <Form.Input
                          label='ยอดคาดการณ์รวม'
                          placeholder=''
                          className='text-right'
                          width={4}
                          value={Utility.numberFormat(items.reduce((sum, item) => sum + (item.estimate), 0))}
                          readOnly />
                        <Form.Input
                          label='ยอดเงินรวม'
                          placeholder=''
                          className='text-right'
                          width={4}
                          value={Utility.numberFormat(items.reduce((sum, item) => sum + Number(item.total), 0))}
                          readOnly />
                      </Form.Group>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        </div>
        </Modal.Content >
        {this.state.data && <Modal.Actions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {(this.state.data && this.state.data.status_stock !== 'Y') && <Button
              size='small'
              secondary
              icon='hide'
              labelPosition='left'
              onClick={(e) => {
                if(window.confirm(`ยืนยันลบรายการ "${this.state.data && this.state.data.number}"`)){
                  this.hideInvoice()
                }
              }}
              className={this.state.button_save_loading ? 'loading' : ''}
              content={'ลบรายการ'}
            />}
          </div>
          <div>
            <Button
              size='small'
              primary
              icon='save'
              labelPosition='left'
              onClick={(e) => {
                if (items.reduce((sum, item) => sum + Number(item.total), 0) !== Number(this.state.data.total)) {
                  alert(`"ยอดเงินรวม"(${Utility.numberFormat(items.reduce((sum, item) => sum + Number(item.total), 0))}) ต้องเท่ากับ "ยอดเงินทั้งหมด"(${Utility.numberFormat(Number(this.state.data ? this.state.data.total : 0))})`)
                } else {
                  this.submitSaveInvoice()
                }
              }}
              className={this.state.button_save_loading ? 'loading' : ''}
              content={'บันทึก'}
            />
            {(this.state.data && this.state.data.status_stock !== 'Y') && <Button
              size='small'
              icon='lightning'
              color='red'
              onClick={(e) => {
                e.preventDefault();
                if (items.reduce((sum, item) => sum + Number(item.total), 0) !== Number(this.state.data.total)) {
                  alert(`"ยอดเงินรวม"(${Utility.numberFormat(items.reduce((sum, item) => sum + Number(item.total), 0))}) ต้องเท่ากับ "ยอดเงินทั้งหมด"(${Utility.numberFormat(Number(this.state.data ? this.state.data.total : 0))})`)
                } else {
                  if (window.confirm(`ยืนยันอนุมัติรายการ ยอดรวม ${Utility.numberFormat(items.reduce((sum, item) => sum + Number(item.total), 0))}`)) {
                    this.submitSaveInvoice(true)
                  }
                }
              }}
              className={this.state.button_save_loading ? 'loading' : ''}
              disabled={this.state.button_save_loading}
              content='บันทึกและอนุมัติ'
            />}
          </div>
        </Modal.Actions>}
      </Modal>
    )
  }

}
const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}

export default connect(
  mapStateToProps,
)(BillModal)